<template>
    <div class="col">
        <h5 class="h6 my-3">{{$t('motor.upozorneni')}}:</h5>
        <div v-for="n in notifications" :key="n.id" class="row border-info border-top py-3">

          <div v-if="n.entity_type=='Motor'" class="col-sm-2">
              {{df(n.act_date, 'LL')}}
          </div>
          <div class="col-sm-2" v-else>
                {{df(n.created_at, 'LL')}}
            </div>

            <div v-if="n.entity_type=='Motor'">
              <b>{{ $t(n.message_code)}}</b>
            </div>
          <div v-else class="col-sm-10">
            <b>{{$t(n.message)}}</b>
          </div>

        </div>
    </div>
</template>

<script>
    const moment = require('moment');
    require('moment/locale/cs');

    const axios = require("axios");
    axios.defaults.withCredentials = true;
    //const MotorHelper = require('../../motor-helper')
    export default {
        name: "Notifications",
        data(){
            return {
                notifications:[],
                motor:[]
            }
        },
        methods:{
            df: function (time, format) {
              moment.locale(this.$i18n.locale);
                if (!time) return '';
                var a = moment(time);
                return a.format(format ? format : "LLL")
            },
            _loadNotifications(){
            /*    const that = this
                axios.get(process.env.VUE_APP_API_ROOT_URL + "/motor/"+this.motor.my_id+`/notifications?timestamp=${new Date().getTime()}`).then(result => {
                    console.log("status motor", result)
                    if (result.status == 200) {
                        this.notifications = result.data
                        //console.log('nasten detail motoru')
                        //this.GetNotifications()
                        const mh = new MotorHelper(this.motor, function(xx){ return that.$t(xx)} )
                        this.notifications = mh.getNotifications()

                    }
                }).catch(e =>{
                    console.error('errrr', e)
                })*/

              this.notifications = [] // mh.getNotifications()
              for(var n=0; n<this.motor.notifications.length; n++){
                let nf = this.motor.notifications[n]
                if (nf.active )this.notifications.push(nf)
              }
            },
        },
        mounted(){
          moment.locale(this.$i18n.locale);
            this.motor = this.$parent.motor
            this.$parent.$on('motorUpdated', () => {
                this.motor = this.$parent.motor
                //console.log('_loadNotifications()', data)
                this._loadNotifications()
            })
            if (this.motor && this.motor.id>0)this._loadNotifications()

        }
    }
</script>

<style scoped>

</style>
